import React from 'react';
import Layout from "../../components/Layout";
import NavOne from "../../components/NavOne";
import PageHeader from "../../components/PageHeader";
import Footer from "../../components/Footer";
import JfiTitle from "../../components/titles/JfiTitle";

const CausesPage = () => {
    return (
        <Layout pageTitle="JCI India Foundation | JFI">
            <NavOne />
            <PageHeader title="JFI" />
            <JfiTitle />
            <Footer />
        </Layout>
    );
};

export default CausesPage;
